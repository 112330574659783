import React from 'react';
import { Link } from 'react-router-dom';
import {Button} from '@mui/material';

const Home = () => {
    let currentLocation = window.location.hostname;

    return (
        <div>
            <h1>Domain for sale.</h1>
            <p>{currentLocation} is now available for sale.</p>
            <p>Make an offer via the form or reach out via mail to valerio.wallin@gmail.com </p>
            {/*<Link to="https://scrive.com/t/9222115557506375429/673b384f1972dcfb">Offer</Link> */}
                  <Button variant="contained" href="https://scrive.com/t/9222115557506375429/673b384f1972dcfb">Form</Button>
        </div>
);
}

export default Home;
