import React from 'react';

import Home from './pages/Home'

import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
        <Home/>
    </div>
  );
}

export default App;
